<template>
    <div :class="`m-3 p-2 wrapper wrapper--default`">
        <Loading v-if="loading" />
        <div v-if="suppliers_stock_sold">
            <Tabs>
                <Tab v-for="(buyer, key) in suppliers_stock_sold" :key="key" :title="key" class="m-2">
                    <table :class="`w-50 table--default table__border--default`">
                        <thead :class="`table__head--default`">
                            <tr>
                                <th class="table__head--text">bu</th>
                                <th class="table__head--text">Leverancier</th>
                                <th class="table__head--text">Naam</th>
                                <th class="table__data--right table__head--text">Aantal in S</th>
                                <th class="table__data--right table__head--text">Aantal in V</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, key) in buyer" :key="key" :class="`table__row--color`">
                                <td class="table__cell--default text-center">{{ item.bu }}</td>
                                <td class="table__cell--default text-center"> <router-link :to="{
                                    name: 'supplierstocksolditems',
                                    params: { levnr: item.leverancier },
                                }">{{ item.leverancier }}</router-link></td>
                                <td class="table__cell--default table__cell--right">{{ item.leveranciernaam }}</td>
                                <td class="table__data--right table__cell--default text-center">
                                    {{ item.AmountS }}
                                </td>
                                <td class="table__data--right table__cell--default text-center">
                                    {{ item.AmountV }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Tab>
            </Tabs>
        </div>
    </div>
</template>
  
<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import Tab from "@/components/Tabs/Tab.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
export default {
    components: { Loading, Tab, Tabs },
    data: () => ({
        loading: true,
        supplier_stock_sold: null,
    }),
    created() {
        this.getData();
    },
    methods: {
        getData() {
            request(`suppliers-stock-sold`, "GET").then(
                ({ suppliers_stock_sold }) => {
                    this.suppliers_stock_sold = suppliers_stock_sold;
                    this.loading = false;
                }
            );
        },
    },
};
</script>
  